
import Vue from 'vue'
import { IMenuItem } from '../store/state'
import MenuLinks from './MenuLinks.vue'
import MenuItem from './MenuItem.vue'
import { IView } from '../store/state'
import { toggleView } from '../store/actions'


// Interfaces for component typechecking!
interface MenuData {

}

interface MenuMethods {
	closeMenu(view: IView): void
}

interface MenuComputed {
	menu: IMenuItem[]
	isSubWeb: boolean
	mainStartPageUri: string
}

interface MenuProps {

}

export default Vue.extend<MenuData, MenuMethods, MenuComputed, MenuProps>({
	name: 'Menu',
	methods: {
		closeMenu() {
			this.$store.dispatch(toggleView({
				name: "menu",
				open: false,
				value: "Meny"
			}))
		}
	},
	computed: {
		menu() {
            return this.$store.getters.menu;
        },
		isSubWeb() {
            return this.$store.getters.isSubWeb;
        },
		mainStartPageUri() {
            return this.$store.getters.mainStartPageUri;
        }
	},
	components: {
		MenuLinks,
		MenuItem
	}
})
