
import Vue from 'vue'
import TopLinks from './components/TopLinks.vue'
import Menu from './components/Menu.vue'
import Search from './components/Search.vue'
import Contact from './components/Contact.vue'
import { IView } from './store/state'

// Interfaces for component typechecking!
interface AppData {

}

interface AppMethods {
	menuOpen(item: IView): void
	closeMenu(): void
	setColors(): void
}

interface AppComputed {
	showMenu: boolean
	showSearch: boolean
	showContact: boolean
	logoUri: string
	isStartPage: boolean
	startPageUri: string
	isSubWeb: boolean
	mainStartPageUri: string
	subWebName: string
}

interface AppProps {

}

export default Vue.extend<AppData, AppMethods, AppComputed, AppProps>({
	name: 'app',
	computed: {
		showMenu() {
			const views = this.$store.getters.views
			const menu = views.find((view: IView) => view.name === 'menu')
			this.menuOpen(menu)
			console.log('Test')

			return menu.open
		},
		showSearch() {
			const views = this.$store.getters.views
			const search = views.find((view: IView) => view.name === 'search')
			this.menuOpen(search)
			console.log('showSearch')

			return search.open
		},
		showContact() {
			const views = this.$store.getters.views
			const contact = views.find((view: IView) => view.name === 'contact')
			this.menuOpen(contact)
			console.log('showContact')

			return contact.open
		},
		logoUri() {
			return this.$store.getters.logoUri
		},
		isStartPage() {
			return this.$store.getters.isStartPage
		},
		startPageUri() {
			return this.$store.getters.startPageUri
		},
		isSubWeb() {
			return this.$store.getters.isSubWeb
		},
		mainStartPageUri() {
			return this.$store.getters.mainStartPageUri
		},
		subWebName() {
			return this.$store.getters.subWebName
		},
	},
	methods: {
		menuOpen(item) {
			// const views = this.$store.getters.views
			const html = document.documentElement
			const menuOpen = item.open//views.some((view: IView) => view.open)
			// const isOpen = html.hasAttribute('menu-open')

			if(menuOpen) {
				html.setAttribute(item.name + '-open', '')
			} else if(!menuOpen) {
				html.removeAttribute(item.name + '-open')
			}
		},
		closeMenu() {
			const views = this.$store.getters.views
			const openView = views.find((view: IView) => view.open)

			this.$store.commit({
				type: 'toggleView',
				payload: openView
			})
		},
		setColors() {
			const root = document.documentElement
			const colors = this.$store.getters.colors
			
			root.style.setProperty('--header-primary', colors.primary)
			root.style.setProperty('--header-secondary', colors.secondary)
			root.style.setProperty('--header-level-2', colors.menuLevel2)
			root.style.setProperty('--header-level-3', colors.menuLevel3)
			root.style.setProperty('--header-level-4', colors.menuLevel4)
			root.style.setProperty('--header-level-5', colors.menuLevel5)
		}
	},
	mounted() {
		this.setColors()

		window.addEventListener('click', (e) => {
			const appRef = this.$refs.header as HTMLElement
			const target = e.target as HTMLElement
			const views = this.$store.getters.views
			const menuOpen = views.some((view: IView) => view.open)
			
			if(appRef && menuOpen) {
				if((appRef).contains(target)) {
				} else {
					if(menuOpen && !target.hasAttribute('data-ignore-close')) {
						this.closeMenu()
					}
					
				}
			}
			
		})
	

	},
	components: {
		TopLinks,
		Menu,
		Search,
		Contact
	}
})
