import { Getter } from "vuex";
import { IState } from './state';

const menu: Getter<IState, IState> = (state) => state.menu;
const searchPageUri: Getter<IState, IState> = (state) => state.endpoints.searchPageUri;
const suggestions: Getter<IState, IState> = (state) => state.suggestions;
const views: Getter<IState, IState> = (state) => state.views;
const logoUri: Getter<IState, IState> = (state) => state.logoUri;
const contact: Getter<IState, IState> = (state) => state.contact;
const eServices: Getter<IState, IState> = (state) => state.eServices;
const bulletinBoard: Getter<IState, IState> = (state) => state.bulletinBoard;
const easyRead: Getter<IState, IState> = (state) => state.easyRead;
const signLanguage: Getter<IState, IState> = (state) => state.signLanguage;
const otherLanguages: Getter<IState, IState> = (state) => state.otherLanguages;
const isStartPage: Getter<IState, IState> = (state) => state.isStartPage;
const isMainStartPage: Getter<IState, IState> = (state) => state.isMainStartPage;
const startPageUri: Getter<IState, IState> = (state) => state.startPageUri;
const mainStartPageUri: Getter<IState, IState> = (state) => state.mainStartPageUri;
const isSubWeb: Getter<IState, IState> = (state) => state.isSubWeb;
const subWebName: Getter<IState, IState> = (state) => state.subWebName;
const colors: Getter<IState, IState> = (state) => state.colors;

export default {
	menu,
	searchPageUri,
	suggestions,
	views,
	logoUri,
	contact,
	eServices,
	bulletinBoard,
	easyRead,
	signLanguage,
	otherLanguages,
	isStartPage,
	isMainStartPage,
	startPageUri,
	mainStartPageUri,
	isSubWeb,
	subWebName,
	colors
}
