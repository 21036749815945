
import Vue from 'vue'

// Interfaces for component typechecking!
interface MenuLinksData {

}

interface MenuLinksMethods {

}

interface MenuLinksComputed {
	eServices: string
	bulletinBoard: string
	easyRead: string
	signLanguage: string
	otherLanguages: string
}

interface MenuLinksProps {

}

export default Vue.extend<MenuLinksData, MenuLinksMethods, MenuLinksComputed, MenuLinksProps>({
	name: 'MenuLinks',
	methods: {
		
	},
	computed: {
		eServices() {
			return this.$store.getters.eServices
		},
		bulletinBoard() {
			return this.$store.getters.bulletinBoard
		},
		easyRead() {
			return this.$store.getters.easyRead
		},
		signLanguage() {
			return this.$store.getters.signLanguage
		},
		otherLanguages() {
			return this.$store.getters.otherLanguages
		}
	},
	components: {

	}
})
