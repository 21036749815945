
import Vue from 'vue'
import { toggleView } from '../store/actions'
import { IView } from '../store/state'

// Interfaces for component typechecking!
interface TopLinksData {

}

interface TopLinksMethods {
	toggleItem(view: IView): void
}

interface TopLinksComputed {

}

interface TopLinksProps {

}

export default Vue.extend<TopLinksData, TopLinksMethods, TopLinksComputed, TopLinksProps>({
	name: 'TopLinks',
	methods: {
		toggleItem(view) {
			this.$store.dispatch(toggleView(view))
		}
	},
	computed: {
		views() {
			return this.$store.getters.views
		}
	},
	components: {

	}
})
